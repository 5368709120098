import React from 'react';

function  AdvancedReactLogo (){
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="166.000000pt" height="124.000000pt" viewBox="0 -5 166.000000 124.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,0) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M0 1045 l0 -195 35 0 35 0 0 151 0 151 121 -150 121 -151 47 -3 c25
-2 121 -2 214 0 l167 3 0 29 0 30 -135 0 -135 0 0 50 0 50 110 0 110 0 0 30 0
30 -110 0 -110 0 0 55 0 55 135 0 135 0 0 30 0 30 -170 0 -170 0 -2 -192 -3
-191 -154 191 -155 192 -43 0 -43 0 0 -195z"/>
<path d="M867 1192 c21 -27 92 -116 158 -200 l120 -151 43 -1 c23 0 42 2 42 5
0 3 -34 48 -76 100 -49 61 -73 99 -68 107 4 7 35 47 68 88 33 41 64 81 69 88
7 9 -1 12 -34 12 -43 0 -46 -2 -99 -69 -29 -39 -57 -68 -61 -65 -4 2 -31 33
-60 69 -50 62 -54 65 -97 65 l-44 0 39 -48z"/>
<path d="M1310 1210 l0 -30 70 0 70 0 0 -168 0 -168 35 4 35 4 0 164 0 164 65
0 65 0 0 30 0 30 -170 0 -170 0 0 -30z"/>
<path d="M903 938 c-83 -102 -81 -98 -31 -98 40 0 46 4 89 58 l46 58 -22 28
-22 28 -60 -74z"/>
<path d="M1230 789 c0 -12 79 -248 90 -268 6 -10 23 16 58 85 l50 99 47 -99
c25 -54 50 -95 54 -90 7 7 101 269 101 280 0 2 -90 4 -200 4 -135 0 -200 -3
-200 -11z"/>
<path d="M191 751 c-25 -25 -35 -79 -27 -146 6 -51 5 -53 -22 -60 -80 -19
-147 -88 -134 -137 7 -27 76 -83 122 -98 36 -12 39 -16 33 -39 -14 -55 -8
-130 14 -158 17 -24 28 -28 67 -27 36 0 57 8 98 36 l52 37 37 -30 c40 -31 98
-59 124 -59 9 0 30 14 47 30 29 30 30 33 27 108 l-4 78 67 32 c66 31 98 66 98
106 0 26 -53 78 -105 102 l-50 24 0 86 c0 82 -1 87 -29 111 -39 33 -81 26
-153 -23 l-53 -36 -32 24 c-79 60 -142 74 -177 39z m138 -49 c19 -15 36 -33
38 -38 2 -6 -11 -27 -29 -48 -28 -31 -41 -38 -85 -43 l-52 -6 -7 37 c-8 44 0
102 18 123 18 22 74 10 117 -25z m251 28 c17 0 33 -62 27 -112 -4 -28 -8 -52
-10 -54 -2 -2 -25 -1 -51 2 -47 7 -71 23 -103 72 -14 20 -14 24 2 42 23 26 97
61 113 55 8 -3 17 -5 22 -5z m-152 -128 c14 -21 13 -22 -27 -22 -45 0 -49 5
-24 32 20 22 31 20 51 -10z m38 -60 c6 -4 26 -33 43 -64 l33 -58 -37 -60 -36
-60 -74 0 -74 0 -30 54 c-17 29 -31 61 -31 70 0 9 16 41 35 71 l35 55 62 0
c35 0 68 -4 74 -8z m-195 -19 c-5 -10 -16 -27 -24 -37 -13 -18 -14 -18 -25 12
-12 34 -6 40 36 41 19 1 21 -2 13 -16z m308 1 c2 -2 -1 -16 -8 -31 l-12 -27
-20 30 c-10 16 -19 33 -19 38 0 8 50 0 59 -10z m-381 -55 c13 -42 13 -51 -3
-93 -10 -25 -22 -46 -27 -46 -19 0 -84 32 -105 52 -43 40 -20 88 57 122 50 22
62 17 78 -35z m487 24 c75 -38 87 -85 33 -126 -29 -21 -98 -51 -103 -45 -1 2
-9 24 -19 50 -16 45 -16 49 1 97 19 55 23 56 88 24z m-430 -143 c8 -16 15 -31
15 -35 0 -9 -48 -6 -55 4 -5 8 13 61 21 61 2 0 10 -13 19 -30z m323 -35 c-2
-1 -18 -5 -36 -8 l-32 -7 22 40 23 40 13 -31 c7 -17 12 -32 10 -34z m-241 -89
c29 -37 30 -42 17 -57 -29 -32 -99 -62 -126 -56 -30 8 -52 59 -43 101 3 14 7
37 10 49 4 23 7 23 57 14 45 -8 58 -16 85 -51z m257 38 c10 -25 7 -114 -4
-135 -20 -37 -99 -19 -148 34 l-24 25 37 40 c34 39 46 44 116 50 9 1 20 -5 23
-14z m-164 -11 c0 -3 -7 -15 -17 -25 -15 -17 -17 -17 -35 -2 -27 25 -22 34 17
34 19 0 35 -3 35 -7z"/>
<path d="M339 481 c-37 -38 -39 -82 -4 -116 50 -51 145 -18 145 50 0 47 -8 64
-41 80 -42 22 -67 18 -100 -14z"/>
<path d="M1004 455 c-18 -19 -23 -31 -19 -48 14 -46 68 -62 98 -29 24 26 21
68 -5 86 -31 22 -45 20 -74 -9z"/>
<path d="M1260 460 c-24 -24 -25 -48 -4 -78 30 -43 104 -17 104 36 0 28 -34
62 -60 62 -11 0 -29 -9 -40 -20z"/>
<path d="M1522 464 c-42 -29 -18 -104 34 -104 26 0 50 20 59 47 4 17 -1 29
-19 48 -29 29 -43 31 -74 9z"/>
<path d="M1387 382 c-16 -37 -50 -62 -84 -62 -9 0 -32 -19 -52 -42 -40 -46
-115 -98 -144 -98 -33 0 -77 57 -77 101 0 55 -23 50 -49 -10 -26 -61 -28 -142
-4 -181 45 -78 124 -78 292 -1 64 30 145 58 185 66 39 8 83 18 97 24 42 16 69
58 69 108 l0 45 -39 -7 c-57 -10 -120 31 -121 78 0 12 -8 17 -28 17 -23 0 -31
-7 -45 -38z"/>
</g>
</svg>
  )
}
export default AdvancedReactLogo;
