import React from 'react';
/* --- import Components ----*/
import { Button } from '../Button';
import { BtnWrapper } from '../Hero/elements';
/* --- import Styles and Images ----*/
import { useMediaQueries } from 'src/utils/MediaQueries';

const ButtonScrollToForm = ({ text, margin, secondary }) => {
  const mqs = useMediaQueries();
  const scrollToTop = () => {
    if (window) {
      window.scrollTo({
        top: mqs.desktopUp ? 0 : 750,
        behavior: 'smooth',
      });
    }
  };
  return (
    <BtnWrapper landing css={{ marginBottom: margin && 60 }}>
      <Button onClick={scrollToTop} secondary={secondary}>
        {text}
      </Button>
    </BtnWrapper>
  );
};

export default ButtonScrollToForm;
