import React from 'react';
/* --- import Components ----*/
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { PartnersProSection } from 'src/components/Partners';
import { HeroWrapper, HeroLeft } from 'src/components/Hero/elements';
import {
  H2,
  HeaderTitle,
  HeaderDescription,
  TextContainer,
  SubtitleDescription,
  Sentence,
} from 'src/components/Typography';
import { AnchorButton } from 'src/components/Button';
/* --- import Style and Logos ----*/
import { mediaQueries } from 'src/style/breakpoints';
import colors from 'src/style/colors';
import ReactLogo from 'src/components/CorporateCourses/Logos/ReactLogo';
import NodeJsLogo from 'src/components/CorporateCourses/Logos/NodeJsLogo';
import TypeScriptLogo from 'src/components/CorporateCourses/Logos/TypeScriptLogo';
import AdvancedReactLogo from 'src/components/CorporateCourses/Logos/AdvancedReactLogo';

const ExecutiveTraining = ({ pageContext: { content }, location }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2B"
      pageName="Formations"
      pageType="page"
      metaTitle="Découvrez nos formations tech pour les professionnels | Le Reacteur"
      metaDescription="Recrutez nos élèves formés au développement Web et Mobile"
    >
      {/* <HeroBG80vh> */}
      <TopbarStaticSpace />
      <Section theme="primary" hero={true} margin>
        <HeroWrapper
          css={{
            maxWidth: 900,
            margin: '0 auto',
            [mediaQueries.desktopUp]: {
              margin: '0 0',
            },
          }}
        >
          <HeroLeft css={{ flex: 1 }}>
            <HeaderTitle>Découvrez nos formations tech pour les professionnels</HeaderTitle>
            <HeaderDescription
              css={{
                marginTop: 30,
                textAlign: 'center',
                [mediaQueries.desktopUp]: {
                  marginRight: 60,
                  textAlign: 'left',
                },
              }}
            >
              Passez expert en programmation Web et simplifiez vos développements en vous formant
              sur des frameworks qui vous ouvriront de nouvelles perspectives et vous feront gagner
              un temps considérable.
            </HeaderDescription>
          </HeroLeft>
        </HeroWrapper>
      </Section>
      {/* </HeroBG80vh> */}

      <Section
        theme="primary"
        hero
        css={{
          [mediaQueries.tabletPortraitUp]: {
            marginTop: '-100px',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',

            [mediaQueries.tabletLandscapeUp]: {
              flexDirection: 'row',
              justifyContent: 'center',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: 32,
              gridRowGap: 32,
            },
          }}
        >
          {Object.values(content)
            .sort((a, b) => {
              return a.position - b.position;
            })
            .map((item) => {
              return (
                <div
                  key={item.id}
                  css={{
                    backgroundColor: '#5442C0',
                    padding: 24,
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 16,
                    [mediaQueries.tabletLandscapeUp]: {
                      flexDirection: 'column',
                      padding: 36,
                      marginBottom: 0,
                    },
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                    }}
                  >
                    <div
                      css={{
                        marginRight: 24,
                        marginTop: 8,
                        width: '75px',
                        height: '55px',
                        display: 'flex',
                        justifyContent: 'center',
                        [mediaQueries.tabletLandscapeUp]: {
                          marginRight: 32,
                        },
                      }}
                    >
                      {item.id === 'react-native' ? (
                        <ReactLogo />
                      ) : item.id === 'react-redux' ? (
                        <ReactLogo />
                      ) : item.id === 'node-js' ? (
                        <NodeJsLogo />
                      ) : item.id === 'typescript' ? (
                        <TypeScriptLogo />
                      ) : item.id === 'react-avance' ? (
                        <AdvancedReactLogo />
                      ) : null}
                    </div>
                    <div css={{ flexShrink: 0 }}>
                      <H2>
                        <SubtitleDescription>{`Formation ${item.name}`}</SubtitleDescription>
                      </H2>
                      <Sentence css={{ fontWeight: 'bold', color: colors.yellow, paddingTop: 6 }}>
                        {item.duration}
                      </Sentence>
                    </div>
                  </div>

                  <div css={{ marginTop: 8 }}>
                    <TextContainer dangerouslySetInnerHTML={{ __html: item.summary }} />
                    <AnchorButton
                      secondary
                      isSmall
                      css={{ marginTop: 8 }}
                      to={`/formation/${item.id}/`}
                    >
                      En savoir plus
                    </AnchorButton>
                  </div>
                </div>
              );
            })}
        </div>
      </Section>
      <PartnersProSection title="Nous les avons formés" theme="secondary" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                url: 'https://www.lereacteur.io/formation/react-redux/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                url: 'https://www.lereacteur.io/formation/react-native/',
              },
              {
                '@type': 'ListItem',
                position: 3,
                url: 'https://www.lereacteur.io/formation/react-avance/',
              },
              {
                '@type': 'ListItem',
                position: 4,
                url: 'https://www.lereacteur.io/formation/typescript/',
              },
              {
                '@type': 'ListItem',
                position: 5,
                url: 'https://www.lereacteur.io/formation/node-js/',
              },
            ],
          }),
        }}
      />
    </Layout>
  );
};

export default ExecutiveTraining;
