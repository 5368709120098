import styled from 'src/style';
import { mediaQueries } from 'src/style/breakpoints';

export const HeroWrapper = styled.div(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [mediaQueries.desktopUp]: {
      flexDirection: 'row',
      justifyContent: 'center',
      // marginTop: '-100px',
    },
  },
  ({ notCentered }) => ({
    [mediaQueries.desktopUp]: {
      alignItems: notCentered ? 'start' : 'center',
    },
  })
);

export const HeroHomeWrapper = styled.div(
  {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  ({ landing }) => ({
    [mediaQueries.desktopUp]: {
      flexDirection: 'row',
      alignItems: landing ? 'start' : 'center',
      justifyContent: 'space-between',
    },
    [mediaQueries.phoneOnly]: {
      alignItems: 'center',
    },
  })
);

export const HeroLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  [mediaQueries.desktopUp]: {
    width: '50%',
    alignItems: 'flex-start',
    paddingRight: 44,
    textAlign: 'initial',
  },
});

export const BtnWrapper = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [mediaQueries.phoneOnly]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  ({ landing }) => ({
    marginTop: landing ? 100 : 32,
    [mediaQueries.phoneOnly]: {
      marginTop: landing && 60,
    },
    [mediaQueries.desktopUp]: {
      justifyContent: !landing && 'initial',
    },
  })
);

export const HeroBG80vh = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  [mediaQueries.phoneOnly]: {
    height: 'auto',
  },
  [mediaQueries.tabletPortraitUp]: {
    height: 'auto',
  },
  [mediaQueries.tabletLandscapeUp]: {
    height: '80vh',
    minHeight: '600px',
    maxHeight: '700px',
  },
});
