import React from 'react';

function TypeScriptLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46">
      <g fill="none" fillRule="evenodd" transform="translate(-780 -968)">
        <path fill="#5C48D3" d="M0 0h1440v1362H0z"></path>
        <rect width="620" height="351" x="735" y="918" fill="#5442C0" rx="6"></rect>
        <g fillRule="nonzero">
          <path fill="#F5F5F5" d="M780 991v23h46v-46h-46z"></path>
          <path
            fill="#5442C0"
            d="M789.872 990.95l-.015 1.905h6.068v17.202h4.293v-17.202h6.068v-1.868c0-1.034-.022-1.897-.052-1.92-.022-.03-3.715-.044-8.192-.037l-8.148.023-.022 1.897zm27.477-1.734c1.188.289 2.095.802 2.927 1.64.43.448 1.07 1.264 1.121 1.46.015.057-2.02 1.387-3.253 2.13-.045.03-.223-.158-.424-.447-.602-.853-1.233-1.221-2.199-1.286-1.419-.094-2.332.628-2.325 1.835 0 .354.052.563.2.852.313.629.892 1.005 2.712 1.77 3.35 1.402 4.784 2.327 5.676 3.642.995 1.466 1.218 3.807.542 5.549-.743 1.892-2.585 3.178-5.178 3.605-.802.137-2.704.115-3.566-.036-1.88-.325-3.662-1.229-4.761-2.413-.431-.463-1.27-1.67-1.219-1.756.023-.029.216-.145.431-.267.208-.116.996-.557 1.738-.976l1.345-.758.282.404c.394.585 1.256 1.387 1.776 1.655 1.493.766 3.543.657 4.554-.224.43-.383.609-.78.609-1.366 0-.527-.067-.758-.35-1.156-.363-.506-1.106-.932-3.216-1.82-2.414-1.012-3.454-1.64-4.405-2.638-.55-.578-1.07-1.502-1.285-2.275-.179-.643-.223-2.255-.082-2.905.498-2.268 2.258-3.85 4.799-4.32.824-.152 2.741-.094 3.55.1z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TypeScriptLogo;
