import React from 'react';

function NodeJsLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <defs>
        <linearGradient id="a" x1=".007%" x2="100%" y1="49.998%" y2="49.998%">
          <stop offset=".53%" stopColor="#FFF"></stop>
          <stop offset="19.38%" stopColor="#E8E8E8"></stop>
          <stop offset="54.23%" stopColor="#C2C2C2"></stop>
          <stop offset="82.35%" stopColor="#ABABAB"></stop>
          <stop offset="100%" stopColor="#A3A3A3"></stop>
        </linearGradient>
        <linearGradient id="b" x1="-3.934%" x2="102.996%" y1="49.991%" y2="49.991%">
          <stop offset="0%" stopColor="#A3A3A3"></stop>
          <stop offset="17.65%" stopColor="#ABABAB"></stop>
          <stop offset="45.77%" stopColor="#C2C2C2"></stop>
          <stop offset="80.62%" stopColor="#E8E8E8"></stop>
          <stop offset="99.47%" stopColor="#FFF"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-122 -969)">
        <path fill="#5C48D3" d="M0 0h1440v1362H0z"></path>
        <rect width="620" height="351" x="85" y="918" fill="#5442C0" rx="6"></rect>
        <g fill="#FFF" fillRule="nonzero">
          <path d="M155.609 1010.589c-.225 0-.447-.058-.644-.172l-2.047-1.211c-.306-.17-.157-.232-.056-.267.409-.141.49-.174.926-.42.046-.027.106-.016.152.012l1.573.933a.2.2 0 00.19 0l6.133-3.54a.193.193 0 00.094-.166v-7.076a.198.198 0 00-.096-.169l-6.129-3.536a.189.189 0 00-.189 0l-6.127 3.538a.196.196 0 00-.097.167v7.076a.19.19 0 00.097.165l1.68.97c.91.456 1.468-.082 1.468-.62v-6.988c0-.098.08-.176.178-.176h.778c.097 0 .178.078.178.176v6.988c0 1.215-.663 1.914-1.815 1.914-.355 0-.634 0-1.413-.384l-1.608-.926a1.295 1.295 0 01-.643-1.119v-7.076c0-.46.245-.889.643-1.117l6.13-3.545c.389-.22.905-.22 1.29 0l6.132 3.543c.398.23.645.659.645 1.118v7.076c0 .46-.247.887-.645 1.119l-6.132 3.54c-.197.115-.42.173-.646.173z"></path>
          <path d="M157.503 1005.714c-2.683 0-3.245-1.231-3.245-2.266 0-.098.078-.176.176-.176h.792c.09 0 .163.063.177.15.12.808.476 1.214 2.1 1.214 1.292 0 1.842-.291 1.842-.977 0-.395-.157-.688-2.165-.886-1.677-.166-2.715-.537-2.715-1.879 0-1.237 1.042-1.976 2.792-1.976 1.965 0 2.937.682 3.06 2.145a.18.18 0 01-.178.193h-.796a.176.176 0 01-.172-.138c-.19-.849-.655-1.12-1.915-1.12-1.411 0-1.575.49-1.575.86 0 .446.193.575 2.098.827 1.885.248 2.78.601 2.78 1.926.001 1.338-1.113 2.103-3.056 2.103z"></path>
        </g>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M166.346 998.292c0 .651-.531 1.182-1.182 1.182a1.184 1.184 0 01-1.182-1.182c0-.67.551-1.182 1.182-1.182a1.18 1.18 0 011.182 1.182zm-2.174-.003a.991.991 0 101.983.007.991.991 0 00-1.983-.007zm.548-.66h.458c.156 0 .463 0 .463.35 0 .244-.156.293-.25.325.181.013.193.132.22.301.012.106.032.287.069.35h-.281c-.006-.063-.05-.4-.05-.419-.02-.075-.045-.112-.14-.112h-.23v.531h-.257v-1.326h-.002zm.25.582h.206c.169 0 .2-.118.2-.187 0-.182-.126-.182-.194-.182h-.212v.369z"
        ></path>
        <path
          fill="#FFF"
          d="M136.62 982.902a.787.787 0 00-.394-.683l-6.5-3.742a.764.764 0 00-.358-.103h-.068a.775.775 0 00-.359.103l-6.502 3.742a.786.786 0 00-.393.683l.014 10.076c0 .14.072.27.196.34.122.071.27.071.39 0l3.865-2.214a.792.792 0 00.393-.682v-4.706c0-.28.15-.54.392-.68l1.645-.947a.783.783 0 01.783 0l1.644.947c.244.14.393.4.393.68v4.706c0 .28.15.538.393.682l3.862 2.212a.393.393 0 00.588-.34l.015-10.074zm31.282-13.85a.393.393 0 00-.583.344v9.977a.275.275 0 01-.411.238l-1.629-.938a.786.786 0 00-.784 0l-6.504 3.754a.785.785 0 00-.393.68v7.509c0 .28.15.54.393.68l6.504 3.756a.79.79 0 00.786 0l6.505-3.756a.787.787 0 00.393-.68v-18.718a.787.787 0 00-.402-.686l-3.875-2.16zm-.603 19.095c0 .071-.037.136-.098.17l-2.234 1.289a.195.195 0 01-.196 0l-2.233-1.288a.195.195 0 01-.099-.17v-2.58c0-.07.037-.134.099-.17l2.233-1.29a.195.195 0 01.196 0l2.234 1.29c.06.036.098.1.098.17v2.58z"
        ></path>
        <g fill="#FFF">
          <path d="M189.56 985.501a.785.785 0 00.39-.678v-1.82a.788.788 0 00-.39-.68l-6.463-3.75a.788.788 0 00-.787-.002l-6.503 3.753a.785.785 0 00-.392.68v7.506c0 .282.152.543.396.683l6.46 3.682c.238.137.53.138.77.005l3.909-2.172a.395.395 0 00.006-.685l-6.544-3.754a.392.392 0 01-.198-.341v-2.353c0-.14.076-.27.197-.341l2.037-1.174c.121-.07.27-.07.391 0l2.037 1.174c.121.07.197.2.197.34v1.852a.391.391 0 00.59.34l3.896-2.265z"></path>
          <path d="M182.607 985.15a.147.147 0 01.15 0l1.249.72a.153.153 0 01.075.13v1.441c0 .054-.03.103-.075.13l-1.248.72a.147.147 0 01-.15 0l-1.247-.72a.153.153 0 01-.075-.13V986c0-.054.029-.103.075-.13l1.246-.72z"></path>
        </g>
        <path
          fill="url(#a)"
          d="M7.024.134a.775.775 0 01.778 0l6.452 3.722a.78.78 0 01.39.675v7.453a.782.782 0 01-.39.675l-6.452 3.725a.778.778 0 01-.78 0L.573 12.66a.78.78 0 01-.39-.675V4.53c0-.278.15-.535.39-.675L7.025.134z"
          transform="translate(139.652 978.517)"
        ></path>
        <path d="M146.669 978.65l-6.458 3.723a.795.795 0 00-.405.675v7.453c0 .18.067.351.181.488l7.26-12.42a.78.78 0 00-.578.081zm.62 16.317a.828.828 0 00.152-.066l6.436-3.725c.241-.14.36-.396.36-.675v-7.453a.804.804 0 00-.165-.502l-6.783 12.421z"></path>
        <path
          fill="#4B4B4B"
          fillRule="nonzero"
          d="M153.874 982.373l-6.422-3.723a.834.834 0 00-.205-.08l-7.26 12.419a.799.799 0 00.22.185l6.46 3.726c.192.11.416.132.62.066l6.784-12.42a.672.672 0 00-.197-.173z"
          opacity="0.66"
        ></path>
        <path d="M140.283 982.331l-.073.042a.795.795 0 00-.406.675v7.453c0 .277.16.535.401.675l6.46 3.725c.174.1.374.128.563.083l-6.945-12.653zm13.952.717c0-.28-.12-.535-.361-.675l-6.422-3.723a.839.839 0 00-.18-.075l6.963 11.896v-7.423z"></path>
        <path
          fill="url(#b)"
          fillRule="nonzero"
          d="M7.787 16.384l6.436-3.725c.241-.14.36-.396.36-.675v-.03L7.62.06a.777.777 0 00-.604.075L.632 3.816l6.946 12.653a.93.93 0 00.209-.085z"
          transform="translate(139.652 978.517)"
        ></path>
      </g>
    </svg>
  );
}

export default NodeJsLogo;
