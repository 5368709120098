import React from 'react';

function ReactLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="48">
      <g fill="none" fillRule="evenodd" transform="translate(-778 -591)">
        <path fill="#5C48D3" d="M0 0h1440v1362H0z"></path>
        <rect width="620" height="345" x="735" y="543" fill="#5442C0" rx="6"></rect>
        <g transform="translate(780 592)">
          <ellipse
            cx="25.247"
            cy="22.957"
            fill="#F9FAFA"
            fillRule="nonzero"
            rx="4.654"
            ry="4.694"
          ></ellipse>
          <g stroke="#F9FAFA" strokeWidth="2.734">
            <path d="M25.247 13.412c6.249 0 12.054.904 16.431 2.424 5.274 1.831 8.517 4.607 8.517 7.12 0 2.62-3.437 5.569-9.1 7.461-4.282 1.431-9.916 2.178-15.848 2.178-6.082 0-11.841-.701-16.171-2.193C3.598 28.513.298 25.526.298 22.957c0-2.494 3.096-5.248 8.296-7.076 4.393-1.545 10.342-2.47 16.652-2.47h0z"></path>
            <path d="M17.009 18.212c3.122-5.46 6.799-10.08 10.29-13.146 4.208-3.693 8.212-5.14 10.37-3.884 2.25 1.308 3.066 5.784 1.863 11.678-.91 4.456-3.084 9.752-6.047 14.935-3.039 5.314-6.518 9.996-9.962 13.033-4.36 3.843-8.573 5.233-10.78 3.95-2.141-1.245-2.96-5.327-1.933-10.783.868-4.61 3.046-10.27 6.198-15.783h0z"></path>
            <path d="M17.017 27.809c-3.131-5.454-5.263-10.975-6.152-15.558-1.071-5.52-.314-9.742 1.843-11.001 2.247-1.313 6.499.21 10.96 4.205 3.373 3.02 6.837 7.565 9.809 12.743 3.047 5.309 5.33 10.687 6.22 15.214 1.124 5.728.214 10.105-1.991 11.392-2.14 1.249-6.054-.074-10.228-3.696-3.527-3.061-7.3-7.791-10.461-13.3v0z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default ReactLogo;
